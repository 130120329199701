var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "index" }, [
      _c("div", { staticClass: "page-body" }, [
        _c("img", { attrs: { src: require("./img/bg.jpg") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-footer" }, [
        _c("p", [
          _c("span", [_vm._v("平日 08:30 ~ 20:00")]),
          _c("span", [_vm._v("假日 09:00 ~ 18:00")]),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("如需帮助请联系：QQ 3466512693 或 2053211847")]),
        _vm._v(" "),
        _c("span", [_vm._v("霍尔果斯数字森林信息技术有限公司")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }