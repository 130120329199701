/**
 * @author zhenkunliu
 * @email zhenkunliu@yangqianguan.com
 * @date 2019-01-28 18:03:15.654
 * @desc generated by yqg-cli@0.2.4
 */

export default {
    name: 'Index',

    title: '无敌鸭',

    head() {
        return {};
    }
};
